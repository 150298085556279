<template>
  <div>
    <regetpwd />
  </div>
</template>

<script>
// @ is an alias to /src
import regetpwd from "@/components/regetpwd";

export default {
  name: "forgetpwd",
  components: {
    regetpwd
  }
};
</script>
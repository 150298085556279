<template>
  <div class="login-page">
    <div class="logo">
      <img src="@/assets/logo.png" @click="toindex()" title="返回首页"/>
    </div>
    <div class="bg"></div>
    <div class="ikon">
        <div class="settop">
            <span class="first">1</span>
            <p class="first_text">身份认证</p>
            <p class="preline"></p>
            <span class="second">2</span>
            <p class="second_text">重置密码</p>
            <p class="preline"></p>
            <span class="second">3</span>
            <p class="second_text">完成</p>
        </div>
      <div class="reset-form">
        <div class="choose_type">
          <el-radio  v-model="user_type" label="1" >诚美优选商家用户</el-radio>
        <el-radio  v-model="user_type" label="2" >虚拟充值平台用户</el-radio>
        </div>
        <div v-if="user_type==1">
          <label class="lab"><i class="required">*</i>身份证号</label>
          <input class="input_text" name="id_card" autocomplete="off" type="text" v-model="idnum" placeholder="输入店铺绑定的身份证号" maxlength="18"/>
        </div>
        <div>
          <label class="lab">&nbsp;&nbsp;<i class="required2">*</i>手机号</label>
          <input class="input_text" type="text" autocomplete="off" name="phone" maxlength="11" v-model="moble" placeholder="输入店铺绑定的手机号" @keyup="enable()" />
        </div>
        <div>
          <label class="lab"><i class="required2">*</i>验证码</label>
          <input class="input_code" type="text" autocomplete="off" name="code" v-model="code" @keyup="enable()" maxlength="6" />
          <div class="getcode" @click="sendverifycode()" v-if="code_show==true">{{coded}}</div>
          <div class="getcode1" v-else-if="code_show==false" >{{coded}}</div>
        </div>
        <div class="hintr" v-if="istrue">手机号可以使用</div>
        <div class="hintf" v-else-if="istrue===''"></div>
        <div class="hintf" v-else>手机号格式错误</div>
        <div class="submit" @click="next_step()" v-if="step">
          <span type="danger" size="large" class="nextbtn">下一步</span>
        </div>
        <div class="submit" @click="unstep()" v-else>
          <span  type="danger" size="large" class="btn">下一步</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "regetpwd",
  // inject:['app'],
  data() {
    return {
      moble: "",
      idnum:"",
      code:"",
      istrue:'',
      show:false,
      step:false,
      user_type:"1",
      coded:'获取验证码',
      totalTime: 60,
      code_show:false,
    };
  },
  mounted() {
    this.$parent.$parent.$parent.sskj = false;
  },
  methods: {
    enable(){
      if(this.code.toString().length==6&&this.moble.toString().length==11){
        this.step=true;
      }else{
        this.step=false;
      }
      if(this.moble.toString().length==11){
        this.code_show=true;
      }else{
        this.code_show=false;
      }
    },
    sendverifycode(){
      var that=this;
      if(this.coded=='获取验证码'){
        this.coded = '发送中';
        let params={'phone':this.moble};
        $.ajax({
          async:false,
          type:'post',
          url:this.$store.state.user.q_url+'/api/api/sendSms',
          data:params,
          dataType:'json',
          success:function(res){
              if(that.totalTime==60){
            var bo_clock = window.setInterval(function () {
            that.totalTime--;
            that.coded =that.totalTime + 's';
            if (that.totalTime < 0) {
            window.clearInterval(bo_clock);
            that.coded = '获取验证码';
            that.totalTime = 60;
            }
            },1000)
          }
        },error:function(){
             that.coded = '获取验证码';
              this.$message({
              message: '发送失败',
              type: 'error',
             center:'true'})
        }           
            })}       
    },
    toindex(){
      this.$router.push('./homepage')
    },
    next_step(){
      if(this.user_type==1){
      this.$router.push(
        {
          path: '/reset_newpwd',
          query: {
            user_type:this.user_type,
            idnum:this.idnum,
            moble:this.moble,
            code:this.code,
          }
        });}else{
          this.$router.push(
        {
          path: '/reset_newpwd',
          query: {
            moble:this.moble,
            code:this.code,
            user_type:this.user_type
          }
        });
        }
    },
    unstep(){
      this.$message({
          message: '请完整填写信息',
          type: 'warning',
          center:'true'
        });
    },
    checkMoble(num) {
      var re = /^1\d{10}$/;
      if (re.test(num)) {
        this.istrue = true;
      } else {
        this.istrue = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.reset-form /deep/ .is-checked .el-radio__inner{border-color: #3D31E2;background-color: #3D31E2;}
.reset-form /deep/ .is-checked+.el-radio__label{color: #3D31E2;font-weight: 400;}
.login-page {
  top: 80px;
  position: fixed;
  padding: 0px;
  margin: 0;
  font-size: 0px;
  height: 80%;
  width: 100%;
  box-sizing: border-box;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(242, 243, 250, 1);
    z-index: -10;
  }
}
.logo {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 360px;
  top: -70px;
  > img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.ikon {
  position: absolute;
  z-index: 5;
  padding: 0;
  align-items: center;
  width: 1300px;
  height: 720px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(222, 222, 222, 1);
  border-radius: 8px;
  > img {
    width: 570px;
    height: 100%;
  }
  .settop{height: 40px;width: 900px;position: absolute;top: 100px;left: 217px;right: 217px;
  >span{display: inline-block;}
  >p{display: inline-block;}
  .first{
      width: 36px;
      height: 36px;
      text-align: center;
      background:rgba(255,255,255,1);
    border:2px solid rgba(222,222,222,1);
    border-radius:50%;
    font-size:20px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(61,49,226,1);
line-height:36px;}
.first_text{width:86px;
height:20px;
font-size:20px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(55,67,214,1);
line-height:26px;
margin: 0;
margin-left: 18px;
}
.second{width:36px;
height:36px;
text-align: center;
background:rgba(204,204,204,1);
border-radius:50%;
font-size:20px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(255,255,255,1);
line-height:36px;
margin-left: 18px;}
.second_text{
    width:86px;
height:20px;
font-size:20px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(51,51,51,1);
line-height:26px;
margin: 0;
margin-left: 18px;
}
.preline{width:200px;
height:4px;
background:rgba(204,204,204,1);
border-radius:1px;
margin-left: 18px;
margin-bottom: 5px;}
  }
  .reset-form {
    display: inline-block;
    position: absolute;
    right: 0;
    left: 0;
    top: 150px;
    margin: auto;
    height: 400px;
    width: 490px;
    background-color: #fff;
    box-sizing: border-box;
    > div {
      border-radius: 20px;
      overflow: hidden;
    }
    .choose_type{
      display: inline-block;
      margin: 5px 2px;
      padding: 3px;
      height: 24px;
      width: 460px;
    }
    .typelab{display: inline-block;width: 130px;height: 24px;font-size: 16px;}
    > p {
      width: 48px;
      height: 24px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 34px;
    }
    .lab {
      width: 51px;
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 33px;
      .required{margin-right: 2px;color: #F42C2E;font-style: normal;}
      .required2{margin-right: 2px;color: #F42C2E;font-style: normal;padding-left: 15px;display: inline-block;}
    }
    .input_text {
      outline: none;
      text-indent: 10px;
      padding-left: 10px;
      font-size: 16px;
      font-weight: 400;
      z-index: 10;
      width:280px;
height:38px;
background:rgba(255,255,255,1);
border:2px solid rgba(238,238,238,1);
margin-left: 20px;
margin-top: 24px;
    }
    .input_code {
      outline: none;
      text-indent: 10px;
      padding-left: 10px;
      font-size: 16px;
      font-weight: 400;
      z-index: 10;
      width:160px;
height:38px;
background:rgba(255,255,255,1);
border:2px solid rgba(238,238,238,1);
margin-left: 20px;
margin-top: 24px;
    }
    .getcode{width:110px;
height:38px;
background:rgba(255,255,255,1);
border:2px solid rgba(238,238,238,1);
display: inline-block;
font-size:14px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(55,67,214,1);
line-height:38px;
text-align: center;
margin-left: 8px;
cursor: pointer;}
.getcode1{width:110px;
height:38px;
background:rgba(255,255,255,1);
border:2px solid rgba(238,238,238,1);
display: inline-block;
font-size:14px;
font-family:Microsoft YaHei;
font-weight:400;
color:#aaa;
line-height:38px;
text-align: center;
margin-left: 8px;
}
    .hintr {
      position: absolute;
      width: 120px;
      height: 30px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgb(64, 241, 10);
      line-height: 33px;
      right: -14px;
      top: 180px;
    }
    .hintf {
      position: absolute;
      width: 120px;
      height: 30px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgb(240, 42, 15);
      line-height: 33px;
      right: -14px;
      top: 180px;
    }
    .submit {
      height: 60px;
      margin: 10px;
      margin-top: 40px;
      cursor: pointer;
    }
    .submit .nextbtn {
      width: 360px;
      height: 60px;
      background: rgba(55, 67, 214, 1);
      border-radius: 30px;
      display: block;
      font-size: 22px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(255,255,255,.7);
      line-height: 60px;
      text-align: center;
    }
    .btn{width: 360px;
      height: 60px;
      background:rgba(153,153,153,1);
      border-radius: 30px;
      display: block;
      font-size: 22px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(255,255,255,.7);
      line-height: 60px;
      text-align: center;}
  }
}
</style>